// Event object setup. Startdate and enddate must only contain the month and day. The year to be used will be calculated based on todays date and the end date of the event
const events = {
	lente: {
		startDate: '03-20',
		endDate: '06-20'
	},

	koningsdag: {
		startDate: '04-27',
		endDate: '04-28'
	},
};

// Loop over the events object. Instantiating a new countdown timer for each of them. Pass the current name and event props to the function
for (event in events) {
	const countdownTimerInstance = new countdownTimer(event, events[event]);
	countdownTimerInstance.init();
}

function countdownTimer(event, eventProps) {
	// Properties used to keep track of the information about the current event. These can be accessed throughout the whole countdownTimer function
	this.countdownElement = null;
	this.eventType = event;
	this.eventStartdate = eventProps.startDate;
	this.eventEnddate = eventProps.endDate;
	this.countdownTarget = null;
	this.timer = null;
	this.distance = null;
	this.today = new Date();

	this.init = function () {
		const _this = this;

		this.countdownElement = document.getElementById('js-countdown-event--' + this.eventType);
		this.countdownEnddate = this.calculateCountdownEnddate(); // We need to calculate the end date of the event
		this.countdownTarget = this.calculateCountdownTarget(); // We need to calculate the date we are counting down to. Based on the current date and end date of the event

		// Start a timer. Execute every 1000 ms
		this.timer = setInterval(function () {
			let notice = 'Nee, het duurt nog even voordat het ' + _this.eventType + ' is.'; // Set the default notice

			_this.today = new Date();
			_this.distance = _this.countdownTarget - _this.today; // Calculate the "distance" between now, and the start date of the event

			switch (true) {
				case (_this.distance < 0): // We reached the event start date
					_this.countdownElement.style.display = 'none'; // Hide the countdown timer
					notice = 'JA, het is nu ' + _this.eventType + '! <br /> Fijne ' + _this.eventType + '!';
					break;

				case (_this.calculateMonthDifference(2)): // There's 2 months left until the start date of the event
					notice = 'Het is nog geen ' + _this.eventType + '... <br /> Ik begrijp je enthousiasme, maar je moet nog wel even wachten.';
					break;

				case (_this.calculateMonthDifference(1)): // There's 1 month left until the start date of the event
					notice = 'Het is nog geen ' + _this.eventType + '... <br /> Maar het duurt niet heel lang meer!!';
					break;

				case (_this.calculateMonthDifference(0)): // We're in the same month as the event start date month
					notice = 'Het is nog geen ' + _this.eventType + '... <br /> Maar wel bijna!!';
					break;

				case (_this.eventJustPassed()): // We're in the same month as the event ended and the last day of the event passed
					_this.countdownElement.style.display = 'block'; // Show the countdown timer
					notice = 'Nee, het is geen ' + _this.eventType + '... <br /> Het is voorlopig geen ' + _this.eventType;
					break;
			}

			_this.setNotice(notice); // Set the notice, based on the above switch conditions
			_this.updatePlaceholders(); // Always update the placeholders during execution of the interval

			// During the last day of the event, start recalculating every second, because after midnight the timer should reset itself and countdown to the same date, but next year.
			if (_this.countdownEnddate - _this.today < 0) {
				_this.countdownEnddate = _this.calculateCountdownEnddate(); // We need to calculate the end date of the event
				_this.countdownTarget = _this.calculateCountdownTarget(); // We need to calculate the date we are counting down to. Based on the current date and end date of the event
			}
		}, 1000);
	}

	// We need to calculate which date to count down to. If the end date of the event has already passed, then we need to count down to the same event, but next year
	this.calculateCountdownTarget = function () {
		let year;
		const endDateThisYear = new Date(this.today.getFullYear() + '-' + this.eventEnddate);
		const endDateThisYearTimezoneCorrection = new Date(endDateThisYear.getTime() + this.getTodayWithTimezoneOffset());

		if (this.today >= endDateThisYearTimezoneCorrection) {
			year = this.today.getFullYear() + 1;
		} else {
			year = this.today.getFullYear();
		}

		let countdownTarget = new Date(year + '-' + this.eventStartdate);
		let countdownTargetTimezoneCorrection = new Date(countdownTarget.getTime() + this.getTodayWithTimezoneOffset());

		return countdownTargetTimezoneCorrection;
	}

	// We need the complete event end date with the current year
	this.calculateCountdownEnddate = function () {
		let year;
		const endDateThisYear = new Date(this.today.getFullYear() + '-' + this.eventEnddate);
		const endDateThisYearTimezoneCorrection = new Date(endDateThisYear.getTime() + this.getTodayWithTimezoneOffset());

		if (this.today >= endDateThisYearTimezoneCorrection) {
			year = this.today.getFullYear() + 1;
		} else {
			year = this.today.getFullYear();
		}

		let countdownEnddate = new Date(year + '-' + this.eventEnddate);
		let countdownEnddateTimezoneCorrection = new Date(countdownEnddate.getTime() + this.getTodayWithTimezoneOffset());

		return countdownEnddateTimezoneCorrection;
	}

	// Calculate how many months of difference between now and the date we are counting down to
	this.calculateMonthDifference = function (offset) {
		if (this.today.getMonth() === this.countdownTarget.getMonth() - offset && (this.today.getFullYear() === this.countdownTarget.getFullYear())) {
			return true;
		}
	}

	// Calculate if we are in the month the event ended and the last event day has passed
	this.eventJustPassed = function () {
		if (this.today.getMonth() === this.countdownEnddate.getMonth() && this.today.getDate() >= this.countdownEnddate.getDate()) {
			return true;
		}
	}

	// Set a notice about how much time there's left
	this.setNotice = function (notice) {
		document.getElementById('js-current-info-text-' + this.eventType).innerHTML = notice;
	}

	// Update all of the placeholders with the new values
	this.updatePlaceholders = function () {
		// Time calculations for days, hours, minutes and seconds
		const days = Math.floor(this.distance / (1000 * 60 * 60 * 24));
		const hours = Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((this.distance % (1000 * 60)) / 1000);

		let daysText = (days === 1) ? 'dag' : 'dagen';
		let minutesText = (minutes === 1) ? 'minuut' : 'minuten';
		let secondsText = (seconds === 1) ? 'seconde' : 'seconden';
		const hoursText = 'uur';

		// Display the result in the elements
		document.getElementById('js-number-days-' + this.eventType).innerHTML = days;
		document.getElementById('js-number-hours-' + this.eventType).innerHTML = hours;
		document.getElementById('js-number-minutes-' + this.eventType).innerHTML = minutes;
		document.getElementById('js-number-seconds-' + this.eventType).innerHTML = seconds;

		document.getElementById('js-text-days-' + this.eventType).innerHTML = daysText;
		document.getElementById('js-text-hours-' + this.eventType).innerHTML = hoursText;
		document.getElementById('js-text-minutes-' + this.eventType).innerHTML = minutesText;
		document.getElementById('js-text-seconds-' + this.eventType).innerHTML = secondsText;
	}

	this.getTodayWithTimezoneOffset = function () {
		return (this.today.getTimezoneOffset() * 60 * 1000);
	}
};
