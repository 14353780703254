const cookieConsent = {
    cookieWall: document.querySelector('.cookie-wall'),
    storageKey: 'cookie_consent',

    init: function ()
    {
        var currentLocalStorageValue = localStorage.getItem(this.storageKey);

        this.cookieWall.querySelector('.consent-button').addEventListener('click', function() {
            cookieConsent.allow();
        });

        if (currentLocalStorageValue !== 'true') {
            this.cookieWall.style.display = 'block';
        }
    },

    allow: function ()
    {
        localStorage.setItem(this.storageKey, 'true');
        this.cookieWall.style.display = 'none';
    }
};

cookieConsent.init();
